









































































































































import { useState } from '@/shared/mixins/helpers'
import { computed, defineComponent, ref, watch } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import moment from 'moment'
import utils from '@/shared/mixins/utils'
import variables from '@/shared/variables'
import { UserRole } from '@/shared/enum/general-enum'
import { hasUserRole } from '@/shared/utils/userRoleCheck'

export default defineComponent({
    name: 'RiskAssessmentApproval',
    props: {
        approvalContent: {
            type: Object,
        },
        disable: {
            type: Boolean,
        },
        riskAssessmentDetails: {
            type: Object,
        },
    },
    setup(props) {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const { users } = useState(['users'])
        // get logged in user info
        const loggedInUser: { user: { id; organisations } } = utils.ls.get(variables.LOCAL_STORAGE_ITEMS.USER, {
            decrypt: true,
        })
        // get user id of logged in user
        const userId = loggedInUser && loggedInUser.user.id
        const isUserValidator = hasUserRole(loggedInUser.user.organisations, UserRole.VALIDATOR)
        // get current organization id
        const organisationId = utils.ls.get(variables.LOCAL_STORAGE_ITEMS.ORGANISATION, {
            decrypt: true,
        })
        const riskAssessmentDetails = props.riskAssessmentDetails
        // risk assessment approve form
        const riskApprovalForm = ref({
            // the reviewer is the logged-in user (but cannot be the executed by user, then it's empty)
            APPROVED_BY:
                riskAssessmentDetails &&
                riskAssessmentDetails.executedBy &&
                riskAssessmentDetails.executedBy.id == userId
                    ? 0
                    : userId,
            APPROVAL_DATE: new Date(),
            APPROVAL_REMARK: '',
        })
        const errors = ref({
            APPROVED_BY: { missingValue: false },
            APPROVAL_DATE: { missingValue: false, invalidValue: false },
        })
        const labelInfomationOpApprovedBy = ref(null)
        const labelInfomationOpApprovedDate = ref(null)
        const validators = ref([])

        const validateDate = () => {
            // validation date cannot be in the future or be before nextRsaDate
            const executionDate = new Date(riskAssessmentDetails.executionDate)
            return riskApprovalForm.value.APPROVAL_DATE <= executionDate
        }

        //  validating form
        const validateForm = () => {
            errors.value.APPROVED_BY.missingValue = utils.validateFields(
                riskApprovalForm.value.APPROVED_BY,
                'number'
            )
            errors.value.APPROVAL_DATE.missingValue = utils.validateFields(
                riskApprovalForm.value.APPROVAL_DATE,
                'string'
            )

            errors.value.APPROVAL_DATE.invalidValue = validateDate()
        }

        const getValidators = () => {
            validators.value = []
            // exclude assessment executed user from users list
            const filterdUsers = users.value.filter(
                (user) =>
                    user.id !==
                    (riskAssessmentDetails.executedBy && riskAssessmentDetails.executedBy.id)
            )
            filterdUsers.map((user) => {
                // get user loggedin organisation (current org)
                const currentOrganisation = user.organisations.filter(
                    (org) => org.id === Number(organisationId)
                )
                // get users who are available in current organisation with 'Employee' role
                const filterdValidator =
                    currentOrganisation[0] &&
                    !!currentOrganisation[0].roles.find((role) => role.name === UserRole.VALIDATOR)
                if (filterdValidator) {
                    validators.value.push(user)
                }
            })
        }

        const cancelForm = () => {
            riskApprovalForm.value = {
                APPROVED_BY:
                    riskAssessmentDetails &&
                    riskAssessmentDetails.executedBy &&
                    riskAssessmentDetails.executedBy.id == userId
                        ? 0
                        : userId,
                APPROVAL_DATE: new Date(),
                APPROVAL_REMARK: '',
            }
            errors.value = {
                APPROVED_BY: { missingValue: false },
                APPROVAL_DATE: { missingValue: false, invalidValue: false },
            }
        }

        // open information overlay panel
        const toggleInformation = (event: MouseEvent, field) => {
            utils.findMousePointerLocationOP(event)

            //  toggle overlay popup for each label field
            field.toggle(event)
        }

        // page will be in editable mode under following conditions
        const isEditableMode = computed(() => {
            return !props.approvalContent
        })

        //  watch for risk assessment content changes
        watch(
            () => props.riskAssessmentDetails,
            () => {
                cancelForm()
                if (props.riskAssessmentDetails) {
                    getValidators()
                    riskApprovalForm.value.APPROVED_BY =
                        riskAssessmentDetails.executedBy &&
                        riskAssessmentDetails.executedBy.id == userId
                            ? 0
                        : userId
                    riskApprovalForm.value.APPROVAL_REMARK = riskAssessmentDetails?.approvalRemark || ''
                }
            },
            { immediate: true }
        )

        return {
            t,
            language,
            riskApprovalForm,
            validators,
            moment,
            validateForm,
            errors,
            cancelForm,
            labelInfomationOpApprovedBy,
            labelInfomationOpApprovedDate,
            toggleInformation,
            utils,
            isEditableMode,
            isUserValidator,
            userId,
        }
    },
})























import { defineComponent, ref, computed } from "@vue/composition-api"
import { useI18n } from 'vue-i18n-composable'
import { useState } from '@/shared/mixins/helpers'

export default defineComponent({
    name: 'InfoIcon',
    props: {
        infoTranslationKey: {
            type: String,
            default: null,
        },
        infoText: {
            type: String,
            default: null,
        },
    },
    setup (props) {
        const { t } = useI18n()
        const { language } = useState(['language'])

        // Holds ref to the OverlayPanal component
        const labelInformationOp = ref(null)

        const topPosition = ref(0);

        // open information overlay panel
        const toggleInformation = (event: MouseEvent) => {
            topPosition.value = event.pageY
            labelInformationOp.value.toggle(event)
        }

        const infoHTML = computed(() => {
            if (props.infoTranslationKey)
                return t(props.infoTranslationKey, language.value)
            return props.infoText
        })
        return {
            toggleInformation,
            t,
            language,
            infoHTML,
            labelInformationOp,
            topPosition
        }
    }
})












































































































































































































































































































































































































































































































































































import { useState } from '@/shared/mixins/helpers'
import { computed, defineComponent, inject, Ref, ref, watch, PropType } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import moment from 'moment'
import CircleCmp from '@/shared/components/CircleCmp.vue'
import riskdata from '@/assets/data/risk-assessment-data.json'
import utils from '@/shared/mixins/utils'
import variables from '@/shared/variables'
import { RiskAssessmentDetailsDTO } from '@/dto/backend-response/riskAssessmentsDTO'
import InfoIcon from '@/shared/components/InfoIcon.vue'
import type { RiskAssessmentFormDTO } from '@/dto/forms/riskAssessmentFormDTO'
import DocumentationLink from '@/shared/components/DocumentationLink.vue'
import { hasUserRole } from '@/shared/utils/userRoleCheck'
import { UserRole } from '@/shared/enum/general-enum'

const defaultFormData = (): RiskAssessmentFormDTO => ({
    EXECUTED_BY: utils.getLoggedInUser()?.id,
    RISK_STRATEGY: 0,
    RISK_RESPONSE: '',
    RISK_EXPLANATION: '',
    EXECUTION_DATE: new Date(),
    NEXT_RSA_DATE: moment().add(1, 'year').toDate(),
    GROSS_IMPACT: 0,
    GROSS_CHANCE: 0,
    NET_IMPACT: 0,
    NET_CHANCE: 0,
    AMBITION_IMPACT: 0,
    AMBITION_CHANCE: 0,
    DOCUMENTATION_URL: '',
})

const formDataFromAssessment = (assessment: RiskAssessmentDetailsDTO): RiskAssessmentFormDTO => ({
    EXECUTED_BY: assessment.executedBy.id,
    RISK_STRATEGY: assessment.riskStratergy,
    RISK_RESPONSE: assessment.riskResponse,
    RISK_EXPLANATION: assessment.riskAssessmentExplanation,
    EXECUTION_DATE: moment(assessment.executionDate).toDate(),
    NEXT_RSA_DATE: moment(assessment.nextRsaDate).toDate(),
    GROSS_IMPACT: assessment.grossImpact,
    GROSS_CHANCE: assessment.grossChance,
    NET_IMPACT: assessment.netImpact,
    NET_CHANCE: assessment.netChance,
    AMBITION_IMPACT: assessment.ambitionImpact,
    AMBITION_CHANCE: assessment.ambitionChance,
    DOCUMENTATION_URL: assessment.documentationUrl,
})

const prefillFormFromExisting = (
    sourceAssessment: RiskAssessmentDetailsDTO
): RiskAssessmentFormDTO => {
    // Initialize form with data from the source assessment
    const sourceAssessmentData = formDataFromAssessment(sourceAssessment)
    // Reset instance-specific fields to default values
    const { EXECUTION_DATE, NEXT_RSA_DATE } = defaultFormData()
    return {
        ...sourceAssessmentData,
        EXECUTION_DATE,
        NEXT_RSA_DATE,
    }
}

interface Errors {
    EXECUTED_BY: { expire: boolean };
    GROSS_IMPACT: { missingValue: boolean; invalidValue: boolean };
    NET_IMPACT: { missingValue: boolean; invalidValue: boolean };
    NET_CHANCE: { missingValue: boolean; invalidValue: boolean };
    GROSS_CHANCE: { missingValue: boolean; invalidValue: boolean };
    RISK_STRATEGY: { missingValue: boolean };
    DOCUMENTATION_URL: { invalidValue: boolean };
}

const initializeErrors = (): Errors => ({
    EXECUTED_BY: { expire: false },
    GROSS_IMPACT: { missingValue: false, invalidValue: false },
    NET_IMPACT: { missingValue: false, invalidValue: false },
    NET_CHANCE: { missingValue: false, invalidValue: false },
    GROSS_CHANCE: { missingValue: false, invalidValue: false },
    RISK_STRATEGY: { missingValue: false },
    DOCUMENTATION_URL: { invalidValue: false },
})

const validateFormData = (
    form: RiskAssessmentFormDTO,
    employees: Array<{ id: number; userExpired: boolean }>
): Errors => {
    const errors = initializeErrors()
    // missingValue check
    errors.NET_IMPACT.missingValue = utils.validateFields(form.NET_IMPACT, 'number')
    errors.NET_CHANCE.missingValue = utils.validateFields(form.NET_CHANCE, 'number')
    errors.RISK_STRATEGY.missingValue = utils.validateFields(form.RISK_STRATEGY, 'number')

    // Gross risk impact cannot be smaller (equal is ok) than Net impact (note: only valid if gross impact is filled out)
    if (form.GROSS_IMPACT !== 0) {
        if (form.GROSS_IMPACT < form.NET_IMPACT) {
            errors.GROSS_IMPACT.invalidValue = true
            errors.NET_IMPACT.invalidValue = true
        } else {
            errors.GROSS_IMPACT.invalidValue = false
            errors.NET_IMPACT.invalidValue = false
        }
    } else {
        errors.GROSS_IMPACT.invalidValue = false
        errors.NET_IMPACT.invalidValue = false
    }

    // Gross risk chance cannot be smaller (equal is ok) than Net chance (note: only valid if gross chance is filled out)
    if (form.GROSS_CHANCE !== 0) {
        if (form.GROSS_CHANCE < form.NET_CHANCE) {
            errors.GROSS_CHANCE.invalidValue = true
            errors.NET_CHANCE.invalidValue = true
        } else {
            errors.GROSS_CHANCE.invalidValue = false
            errors.NET_CHANCE.invalidValue = false
        }
    } else {
        errors.GROSS_CHANCE.invalidValue = false
        errors.NET_CHANCE.invalidValue = false
    }
    /* check whether selected owner is expired or not.
    Eventhough user cannot select an expired user, this will need if the already selected user is an expired user.
    In this case, it will ask to select an active user
        */
    const executedBy = employees.find((employee) => employee.id === form.EXECUTED_BY)
    if (executedBy) errors.EXECUTED_BY.expire = executedBy.userExpired
    return errors
}

// Scores derived from the form data
const computeRiskScores = (form: RiskAssessmentFormDTO) => ({
    netRisk: form.NET_CHANCE * form.NET_IMPACT,
    grossScore: form.GROSS_CHANCE * form.GROSS_IMPACT,
    ambitionScore: form.AMBITION_CHANCE * form.AMBITION_IMPACT,
})

export default defineComponent({
    name: 'RiskAssessmentSection',
    props: {
        riskAssessmentDetails: {
            type: Object as PropType<RiskAssessmentDetailsDTO>,
        },
        editable: {
            type: Boolean,
        },
        /**
         * When creating a new assessment, base assessment will be used to pre-fill the form data
         */
        newAssessmentSource: {
            type: Object as PropType<RiskAssessmentDetailsDTO>,
        },
    },
    components: {
        CircleCmp,
        InfoIcon,
        DocumentationLink,
    },
    setup(props) {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const { users } = useState(['users'])
        const employees = computed(() => utils.getEmployees(users.value))
        const clientConfigData: Ref = inject('clientConfigData')

        // Form data and errors
        const RISKASSESSMENT_FORM: Ref<RiskAssessmentFormDTO> = ref(defaultFormData())
        const errors = ref(initializeErrors())
        const executedByUser = ref(0)
        // get logged in user info
        const loggedInUser: { user: { id: number; organisations } } | null = utils.ls.get(
            variables.LOCAL_STORAGE_ITEMS.USER,
            {
                decrypt: true,
            }
        )
        // get user id of logged in user
        const userId = loggedInUser && loggedInUser.user.id
        const isUserEmployee = hasUserRole(loggedInUser.user.organisations, UserRole.EMPLOYEE)
        /**
         * Watch for props change, and set form data accordingly
         * We are not using deep watchers, so this will not trigger if we are only
         * changing one property of the assessment. The assessment should be treated
         * as an immutable object and replaced completely when it changes.
         */
        watch(
            [() => props.riskAssessmentDetails, () => props.newAssessmentSource],
            ([details, newAssessmentSource]: [
                RiskAssessmentDetailsDTO,
                RiskAssessmentDetailsDTO
            ]) => {
                if (details) {
                    // Show data for an existing assessment
                    RISKASSESSMENT_FORM.value = formDataFromAssessment(details)
                } else if (newAssessmentSource) {
                    // Show form for a new assessment, but pre-fill based on base assessment
                    const riskAssessmentValues = prefillFormFromExisting(newAssessmentSource)
                    RISKASSESSMENT_FORM.value = { ...riskAssessmentValues, EXECUTED_BY: isUserEmployee ? userId : riskAssessmentValues.EXECUTED_BY}
                } else {
                    // Create a new form with default data
                    RISKASSESSMENT_FORM.value = defaultFormData()
                }
            },
            { immediate: true }
        )

        const validateForm = () => {
            errors.value = validateFormData(RISKASSESSMENT_FORM.value, employees.value)
        }

        const cancelForm = () => {
            RISKASSESSMENT_FORM.value = defaultFormData()
            errors.value = initializeErrors()
        }

        // Computed properties
        const riskScores = computed(() => computeRiskScores(RISKASSESSMENT_FORM.value))
        const riskDetailsStrategy = computed(
            () =>
                riskdata.RISK_STRATEGY.find(
                    (val) => val.SCORE === RISKASSESSMENT_FORM.value.RISK_STRATEGY
                ).VALUE
        )
        const riskStrategyDropdown = computed(() =>
            riskdata.RISK_STRATEGY.map((strategy) => ({
                score: strategy.SCORE,
                value: t(strategy.VALUE, language.value),
            }))
        )
        const impactOptionsDropdown = computed(() =>
            riskdata.IMPACT_OPTIONS.map((option) => ({
                score: option.SCORE,
                value: t(option.VALUE, language.value),
            }))
        )
        const chanceOptionsDropdown = computed(() =>
            riskdata.CHANCE_OPTIONS.map((option) => ({
                score: option.SCORE,
                value: t(option.VALUE, language.value),
            }))
        )

        /*Disable Executor selection when:
            1. Editing Assessment && logged in user is not the executor of the assessment
            2. Avoid disable on New or Pre-filled assessment creation
        */
        const isDisableExecuterField = computed(() => {
            if (!props.riskAssessmentDetails) return false
            return props.riskAssessmentDetails && props.riskAssessmentDetails?.executedBy.id !== userId
        })
        return {
            language,
            RISKASSESSMENT_FORM,
            errors,
            validateForm,
            cancelForm,
            employees,
            riskStrategyDropdown,
            impactOptionsDropdown,
            chanceOptionsDropdown,
            riskDetailsStrategy,
            clientConfigData,
            riskScores,
            utils,
            moment,
            t,
            isDisableExecuterField,
            isUserEmployee
        }
    },
})

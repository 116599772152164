































































import { defineComponent, ref, watch } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import { useState } from '../mixins/helpers'
import generalData from '@/assets/data/general-data.json'
import variables from '@/shared/variables'
import utils from '../mixins/utils'

export default defineComponent({
    name: 'BIVTable',
    props: {
        bivData: {
            type: Object,
            required: false,
        },
        enableEdit: {
            type: Boolean,
        }
    },
    setup(props) {
        const labels = ref({})
        const { t } = useI18n()
        const { language } = useState(['language'])
        const BIVdropdown = ref([])
        const role = useState(['role'])
        const BIV = ref({
            B: t('NOT_APPLICABLE', language),
            I: t('NOT_APPLICABLE', language),
            V: t('NOT_APPLICABLE', language),
        })

        // set table header labels
        const setLabels = () => {
            labels.value = {
                impactAvailability: t('THREATS_TABLE_COLUMN_IMPACT_AVAILABILITY', language.value),
                impactIntegrity: t('THREATS_TABLE_COLUMN_IMPACT_INTEGRITY', language.value),
                impactConfidentiality: t(
                    'THREATS_TABLE_COLUMN_IMPACT_CONFIDENTIALITY',
                    language.value
                ),
            }
        }

        // set biv dropdown values
        const setBIVdropdown = () => {
            BIVdropdown.value = []

            generalData.BIV.map((biv) => {
                BIVdropdown.value.push({
                    key: biv.KEY,
                    value: t(biv.VALUE, language.value),
                })
            })
        }

        // set biv form data from props
        const BIVFormData = () => {
            BIV.value = {
                B: props.bivData.impactAvailability ,
                I: props.bivData.impactIntegrity ,
                V: props.bivData.impactConfidentiality,
            }         
        }

        // convert keys in to name values
        const convertKeyToNames = (key) => {
            // if datashare is true, mapping id of Yes (1) or No(2) from Yes/No dropdown
            const bivName = utils.keyToName(
                generalData.BIV,
                key
            )
            return t(bivName, language.value)
        }

        // reset biv table fields
        const resetBIVTable = () => {
            BIV.value = {
                B: t('NOT_APPLICABLE', language),
                I: t('NOT_APPLICABLE', language),
                V: t('NOT_APPLICABLE', language),
            }
        }

        // watch for language chaneges and update labels
        watch(
            language,
            () => {
                setLabels()
                setBIVdropdown()
                if(props.bivData)
                BIVFormData()
                },
            {
                immediate: true,
            }
        )

        //  watch for biv data changes and set biv form data
        watch(
            () => props.bivData,
            () => {
                if(props.bivData){
                    BIVFormData()
                }else {
                    resetBIVTable()
                }
                
            }
        )

        return {
            t,
            language,
            labels,
            BIV,
            BIVdropdown,
            variables,
            role,
            convertKeyToNames,
            resetBIVTable
        }
    },
})

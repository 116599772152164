


























































































import { useState } from '@/shared/mixins/helpers'
import { defineComponent } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import BIVTable from '@/shared/components/BIVTable.vue'
import utils from '@/shared/mixins/utils'
export default defineComponent({
    name: 'RiskSection',
    props: {
        riskDetails: {
            type: Object,
        },
    },
    components: { BIVTable },
    setup() {
        const { t } = useI18n()
        const { language } = useState(['language'])

        return {
            t,
            language,
            utils
        }
    },
})

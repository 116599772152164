

























































































































































































































































































































import { useState } from '@/shared/mixins/helpers'
import { computed, defineComponent, inject, Ref, ref, watch } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import apolloClient from '@/shared/services/ApolloCLientAPI'
import { ApolloQueryResult } from '@apollo/client/core'
import { useToast } from 'vue-toastification/composition'
import RiskSection from '@/components/risk-assessment/RiskSection.vue'
import RiskAssessmentSection from '@/components/risk-assessment/RiskAssessmentSection.vue'
import router from '@/router'
import RiskAssessmentApproval from '@/components/risk-assessment/RiskAssessmentApproval.vue'
import utils from '@/shared/mixins/utils'
import riskQueries from '@/shared/queries/riskQueries'
import { Status, UserRole } from '@/shared/enum/general-enum'
import generalData from '@/assets/data/general-data.json'
import variables from '@/shared/variables'
import Confirmation from '@/shared/components/Confirmation.vue'
import { Route } from 'vue-router'
import removeMd from 'remove-markdown'
import type {
    RiskAssessmentResponseDTO,
    RiskAssessmentDetailsDTO,
} from '@/dto/backend-response/riskAssessmentsDTO'
import type { RiskResponseDTO } from '@/dto/backend-response/risksDTO'
import type { RiskAssessmentFormDTO } from '@/dto/forms/riskAssessmentFormDTO'

const fetchRiskAssessment = async (
    id: number
): Promise<ApolloQueryResult<RiskAssessmentResponseDTO>> => {
    const getRiskDetailQuery = `
        query{
            riskAssessment(id: ${id}){
                assessment{
                    ${riskQueries.RISK_ASSESSMENT_DETAILS}
                }
                status
                error
            }
        }
    `
    try {
        return apolloClient.getGraphqlData(getRiskDetailQuery)
    } catch (err) {
        throw Error('Error while retrieving risk assessment details')
    }
}

interface ExtendedRiskAssessmentDTO extends RiskAssessmentDetailsDTO {
    color: string;
    info: string;
    infoKey: string;
}

// Extend Risk Assessment with the extra properties we are using here
const extendRiskAssessment = (
    assessment: RiskAssessmentDetailsDTO,
    validationMode: boolean
): ExtendedRiskAssessmentDTO => {
    const alertsMenuType: string = validationMode ? 'menu-validation' : 'not-validation-mode'
    const alert = utils.getAlertInfo(alertsMenuType, assessment)
    return {
        ...assessment,
        ...alert,
    }
}

const fetchRisk = async (id: number): Promise<ApolloQueryResult<RiskResponseDTO>> => {
    const getRiskDetailQuery = `
        query{
            risk(id:${id}){
                ${riskQueries.RISK_DETAILS}
            }
        }
    `
    return apolloClient.getGraphqlData(getRiskDetailQuery)
}

// Patch RiskAssessmentDetailsDTO type to accept id's instead of
// full objects for related entities
type SaveAssessmentInput = Partial<
    Omit<RiskAssessmentDetailsDTO, 'risk' | 'executedBy'> & {
        risk: number;
        executedBy: number;
    }
>

const riskAssessmentFromForm = async (
    form: RiskAssessmentFormDTO,
    riskId: number
): Promise<SaveAssessmentInput> => ({
    risk: riskId,
    executionDate: form.EXECUTION_DATE,
    executedBy: form.EXECUTED_BY,
    grossChance: form.GROSS_CHANCE,
    grossImpact: form.GROSS_IMPACT,
    netChance: form.NET_CHANCE,
    netImpact: form.NET_IMPACT,
    ambitionImpact: form.AMBITION_IMPACT,
    ambitionChance: form.AMBITION_CHANCE,
    riskStratergy: form.RISK_STRATEGY,
    riskResponse: form.RISK_RESPONSE,
    nextRsaDate: form.NEXT_RSA_DATE,
    riskAssessmentExplanation: form.RISK_EXPLANATION,
    documentationUrl: form.DOCUMENTATION_URL,
})

export default defineComponent({
    name: 'RiskAssessment',
    components: { RiskSection, RiskAssessmentSection, RiskAssessmentApproval, Confirmation },
    props: {
        riskId: {
            type: Number,
        },
        lastRecordNo: {
            type: Number,
        },
        recordNo: {
            type: Number,
        },
        firstRecordNo: {
            type: Number,
        },
        /**
         * If non-null, show the existing risk assessment with id === riskAssessmentId.
         * If null, show form for creating a new assessment.
         */
        riskAssessmentId: {
            type: Number,
        },
        /**
         * When creating a new assessment, this determines whether an existing assessment is used
         * to pre-populate the new assessment:
         *  - When newAssessmentSourceId is null, create an empty assessment.
         *  - When newAssessmentSourceId is non-null, copy data from the assessment with that ID.
         */
        newAssessmentSourceId: {
            type: Number,
            default: null,
        },
        accessType: {
            type: String,
        },
    },
    setup(props, { emit }) {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const RiskAssessmentSection = ref(null)
        const toast = useToast()
        const risk = ref([])
        const riskList = ref([])
        // eslint-disable-next-line
        const riskDetails: any = ref(null)
        // eslint-disable-next-line
        const riskAssessmentDetails: any = ref(null)
        const newRiskAssessmentSource: Ref<RiskAssessmentDetailsDTO> = ref(null)
        // Determines if the assessment is shown as an editable form or read-only
        const editable: Ref<boolean> = ref(false)
        const enableApproval = ref(false)
        const RiskAssessmentApproval = ref(null)
        const approvalContent = ref(null)
        const infomationOp = ref(null)
        const { role } = useState(['role'])
        const isOwnAssessment = ref(false)
        const nameInitals = ref('')
        const displaySaveConfirmation = ref(false)
        const displayCancelConfirmation = ref(false)
        const displayApproveConfirmation = ref(false)
        const displaydeclinedConfirmation = ref(false)
        const displaySubmitConfirmation = ref(false)
        const originComponents = ref({
            riskAssessment: 'RiskAssessments',
            riskList: 'Risks',
        })
        const loading = ref(false)
        const riskAssessmentInfobox: Ref = ref('')
        const VIEW_VALIDATION_MODE: Ref<boolean> = inject('VIEW_VALIDATION_MODE', ref(false))

        // default width to fall back if fails in px (150px)
        const fullDescriptionWidth = ref(150)
        // go to record (previous/ next)
        const goToRecord = (type) => {
            emit('go-to-record', { no: props.recordNo, type, riskId: props.riskId })
        }

        // convert id values to name values
        const convertIdToNames = (state) => {
            const stateVal = utils.idToName(generalData.STATUS, state)

            return t(stateVal, language.value)
        }

        const clearAssessmentData = () => {
            approvalContent.value = null
            riskDetails.value = null
            enableApproval.value = false
        }

        // get risk details for the selected risk
        const createEmptyAssessment = async () => {
            riskDetails.value = null
            riskAssessmentDetails.value = null
            loading.value = true
            /* when assessment is not available, get risk details separately using props.riskId.
            When the assessment is available, can fetch risk details from assessment data itself. This will be only required
            when user creates a new risk assessment */
            fetchRisk(props.riskId)
                .then((response) => {
                    loading.value = false
                    riskDetails.value = response.data.risk
                })
                .catch(() => {
                    loading.value = false
                    throw Error('Error while retrieving risk details')
                })
        }

        // Set data derived form the assessment user
        const setAssessmentExecutor = (assessment: RiskAssessmentDetailsDTO) => {
            // TODO: use computed properties for this
            const username = assessment?.executedBy?.username
            // Disable approval if the logged-in user is the user that executed it
            isOwnAssessment.value = utils.disableApprovalSection(username)
            const fullNameOwner =
                assessment?.executedBy?.firstName + ' ' + assessment?.executedBy?.lastName
            nameInitals.value = utils.getNameInitials(fullNameOwner)
        }

        const setApprovalContent = (assessment: RiskAssessmentDetailsDTO) => {
            if (assessment?.state === Status.submitted || assessment?.state === Status.inprogress) {
                // If we are in the submitted or inprogress state, assessment is not
                // yet approved, so approval content should be cleared.
                approvalContent.value
            } else if (assessment?.approvedBy?.id) {
                approvalContent.value = {
                    approvedBy: assessment.approvedBy,
                    approvalDate: assessment.approvalDate,
                    approvalRemark: assessment.approvalRemark,
                }
            }
        }

        const setRiskAssessmentData = (assessment: RiskAssessmentDetailsDTO) => {
            // storing assessment details for assessment section
            riskAssessmentDetails.value = extendRiskAssessment(
                assessment,
                VIEW_VALIDATION_MODE.value
            )
            // set risk details
            riskDetails.value = assessment.risk
            editable.value = false
            setAssessmentExecutor(assessment)
            // Set approval data based on the assessment
            setApprovalContent(assessment)
        }

        const showExistingAssessment = async () => {
            clearAssessmentData()
            loading.value = true
            fetchRiskAssessment(props.riskAssessmentId)
                .then((response) => {
                    loading.value = false
                    if (response.data?.riskAssessment?.assessment) {
                        return setRiskAssessmentData(response.data.riskAssessment.assessment)
                    }
                })
                .catch(() => {
                    loading.value = false
                    throw Error('Error while retrieving risk assessment details')
                })
        }

        const createAssessmentFromExisting = async (sourceAssessmentId: number) => {
            clearAssessmentData()
            loading.value = true
            fetchRiskAssessment(sourceAssessmentId)
                .then((assessmentResponse) => {
                    loading.value = false
                    if (assessmentResponse.data?.riskAssessment?.assessment) {
                        riskDetails.value =
                            assessmentResponse?.data?.riskAssessment?.assessment?.risk
                        newRiskAssessmentSource.value =
                            assessmentResponse.data.riskAssessment.assessment
                        editable.value = true
                    }
                })
                .catch(() => {
                    loading.value = false
                    throw Error('Error while retrieving risk assessment details')
                })
        }

        // update assessment state
        const updateState = async (status: number) => {
            const inputStatus = {
                riskAssessmentState: status,
            }

            const returnQuery = `riskassessment{
                ${riskQueries.RISK_ASSESSMENT_DETAILS}
            }`
            let resultOfStatus
            try {
                resultOfStatus = await utils.updateDocumentState(
                    riskAssessmentDetails.value.id,
                    returnQuery,
                    inputStatus
                )
            } catch (err) {
                return 'error'
            }

            if (resultOfStatus) {
                setRiskAssessmentData(resultOfStatus.riskassessment)
                // Allow parent to update risk table data assessments details
                emit('post-assessment-update')
                return 'success'
            }
            return 'error'
        }

        // cancel approval
        const cancelApproval = () => {
            RiskAssessmentApproval.value && RiskAssessmentApproval.value.cancelForm()
            enableApproval.value = false
        }

        // get info box text based on navigated menu option
        const getRiskAssessmentInfoBoxText = (route: Route) => {
            riskAssessmentInfobox.value = utils.getInfoTextTranslationKeyFor(
                'risk-assessment',
                route.name
            )
        }

        // button handle logically
        const buttonEnable = () => {
            if (riskAssessmentDetails.value) {
                editable.value = false
            } else {
                editable.value = true
            }
        }

        //  save create new assessment
        const saveAssessment = async () => {
            RiskAssessmentSection.value.validateForm()

            // check for validation errors
            if (utils.hasErrors(RiskAssessmentSection.value.errors)) {
                return
            }

            // risk assessment input object
            const riskAssessmentData = await riskAssessmentFromForm(
                RiskAssessmentSection.value.RISKASSESSMENT_FORM,
                riskDetails.value?.id
            )

            // mutation query
            let mutationQuery
            // create
            if (!riskAssessmentDetails.value) {
                mutationQuery = `mutation ($input: CreateUpdateRiskAssessmentInput!) {
                    createRiskAssessment(input: $input) {
                        assessment{
                            ${riskQueries.RISK_ASSESSMENT_DETAILS}
                        }
                            status
                            error
                }
              }`
            } else {
                // update
                mutationQuery = `mutation ($input: CreateUpdateRiskAssessmentInput!) {
                    updateRiskAssessment(id: ${riskAssessmentDetails.value.id}, input: $input) {
                            status
                            error
                }
              }`
            }

            const input = riskAssessmentData
            let result
            try {
                // update data api call
                result = await apolloClient.updateGraphqlData(mutationQuery, input)
            } catch (err) {
                toast.error(t('RISK_ASSESSMENT_SAVED_ERROR_MESSAGE', language.value))
                throw Error('Error while saving risk assessment')
            }
            // create new
            if (result.data.createRiskAssessment && result.data.createRiskAssessment.status) {
                riskAssessmentDetails.value = result.data.createRiskAssessment.assessment

                // 2- in progress
                const status = await updateState(Status.inprogress)
                buttonEnable()
                if (status === 'success')
                    toast.success(t('RISK_ASSESSMENT_SAVED_SUCCESS_MESSAGE', language.value))
                else toast.error(t('RISK_ASSESSMENT_SAVED_ERROR_MESSAGE', language.value))
            } else if (
                //update
                result.data.updateRiskAssessment &&
                result.data.updateRiskAssessment.status
            ) {
                // when resubmitting declined assessment, resetting approval section content and fields
                approvalContent.value = null
                // 2- in progress
                const status = await updateState(Status.inprogress)
                buttonEnable()

                if (status === 'success')
                    toast.success(t('RISK_ASSESSMENT_SAVED_SUCCESS_MESSAGE', language.value))
                else toast.error(t('RISK_ASSESSMENT_SAVED_ERROR_MESSAGE', language.value))
            } else {
                toast.error(t('RISK_ASSESSMENT_SAVED_ERROR_MESSAGE', language.value))
            }
        }
        //  on click of cancel button
        const cancel = () => {
            if (editable.value && riskAssessmentDetails.value) {
                // if the document is in edit mode, it will exit back to view
                editable.value = false
            } else {
                // if you are in create new form, form fields will be clear
                RiskAssessmentSection.value.cancelForm()
            }
            emit('close-assessment')
        }

        // risk assessment approve
        const submitApprovalContent = async () => {
            const riskAssessmentApprovalForm = RiskAssessmentApproval.value.riskApprovalForm

            // risk assessment approval input object
            const riskAssessmentApprovalData = {
                approvedBy: riskAssessmentApprovalForm.APPROVED_BY,
                approvalDate: riskAssessmentApprovalForm.APPROVAL_DATE,
                approvalRemark: riskAssessmentApprovalForm.APPROVAL_REMARK,
            }

            // mutation query

            // approved

            const mutationQuery = `mutation ($input: RiskAssessmentApprovalInput!) {
                    approveRiskAssessment(id: ${riskAssessmentDetails.value.id}, input: $input) {
                        assessment{
                            ${riskQueries.RISK_ASSESSMENT_APPROVE_DETAILS}
                        }

                            status
                            error
                }
              }`

            const input = riskAssessmentApprovalData
            let result
            try {
                // approve data api call
                result = await apolloClient.updateGraphqlData(mutationQuery, input)
            } catch (err) {
                return 'error'
            }
            // check for success approval
            if (result.data.approveRiskAssessment && result.data.approveRiskAssessment.status) {
                const riskApproval = result.data.approveRiskAssessment.assessment

                // set approval content
                approvalContent.value = {
                    approvedBy: riskApproval.approvedBy,
                    approvalDate: riskApproval.approvalDate,
                    approvalRemark: riskApproval.approvalRemark,
                }

                return 'submitted'
            } else {
                return 'error'
            }
        }

        // on success of approval content submition update the state of assessment as approved
        const approveAssessment = async () => {
            const approval = await submitApprovalContent()
            if (approval === 'submitted') {
                // 4- approved
                const status = await updateState(Status.approved)
                if (status === 'success')
                    toast.success(t('RISK_ASSESSMENT_APPROVE_SUCCESS_MESSAGE', language.value))
                else toast.error(t('RISK_ASSESSMENT_APPROVE_ERROR_MESSAGE', language.value))
            } else {
                toast.error(t('RISK_ASSESSMENT_APPROVE_ERROR_MESSAGE', language.value))
            }
        }

        // on success of approval content submition update the state of assessment as declined
        const declineAssessment = async () => {
            const approval = await submitApprovalContent()
            if (approval === 'submitted') {
                // 5- declined
                const status = await updateState(Status.declined)
                if (status === 'success')
                    toast.success(t('RISK_ASSESSMENT_DECLINE_SUCCESS_MESSAGE', language.value))
                else toast.error(t('RISK_ASSESSMENT_DECLINE_ERROR_MESSAGE', language.value))
            } else {
                toast.error(t('RISK_ASSESSMENT_DECLINE_ERROR_MESSAGE', language.value))
            }
        }

        // open information overlay panel
        const toggleInformation = (event: object) => {
            infomationOp.value.toggle(event)
        }

        // open save confirmation
        const openSaveConfirmation = () => {
            if (riskAssessmentDetails.value) {
                displaySaveConfirmation.value = true
            } else {
                // for new create entity
                saveAssessment()
            }
        }
        // open cancel confirmation
        const openCancelConfirmation = () => {
            // cancel for edit assessment
            if (riskAssessmentDetails.value) {
                displayCancelConfirmation.value = true
            } else if (enableApproval.value) {
                // cancel for approval section
                cancelApproval()
            } else {
                // for new create entity
                displayCancelConfirmation.value = true
            }
        }

        // User has clicked Save button and yes in the confirmation dialog
        const successSaveConfirmation = () => {
            displaySaveConfirmation.value = false
            saveAssessment()
        }

        // User has clicked Cancel button and yes in the confirmation dialog
        const successCancelConfirmation = () => {
            displayCancelConfirmation.value = false
            cancel()
        }

        // User has clicked Submit button and yes in the confirmation dialog
        const successSubmitConfirmation = async () => {
            displaySubmitConfirmation.value = false
            enableApproval.value = true
            const status = await updateState(Status.submitted)
            if (status === 'success')
                toast.success(t('RISK_ASSESSMENT_SUBMIT_SUCCESS_MESSAGE', language.value))
            else toast.success(t('RISK_ASSESSMENT_SUBMIT_ERROR_MESSAGE', language.value))
        }

        // User has clicked Approve button and yes in the confirmation dialog
        const successApproveConfirmation = () => {
            displayApproveConfirmation.value = false
            RiskAssessmentApproval.value.validateForm()
            // check for validation errors
            if (utils.hasErrors(RiskAssessmentApproval.value.errors)) {
                return
            }

            approveAssessment()
        }

        // User has clicked Decline button and yes in the confirmation dialog
        const successDeclinedConfirmation = () => {
            displaydeclinedConfirmation.value = false
            RiskAssessmentApproval.value.validateForm()
            // check for validation errors
            if (utils.hasErrors(RiskAssessmentApproval.value.errors)) {
                return
            }

            declineAssessment()
        }

        const isUserValidator = computed(() => {
            return role.value === UserRole.VALIDATOR
        })

        // enable submit button based on following conditions
        const isSubmitEnabled = computed(() => {
            const roles: string[] = [
                UserRole.EMPLOYEE,
                UserRole.PERIUM_ADMIN,
                UserRole.DEVELOPER_ADMIN,
            ]
            return (
                !editable.value &&
                !enableApproval.value &&
                !approvalContent.value &&
                riskAssessmentDetails.value &&
                (riskAssessmentDetails.value.state === Status.inprogress ||
                    riskAssessmentDetails.value.state === Status.declined) &&
                roles.includes(role.value)
            )
        })

        // enable approval buttons based on following conditions
        const isApprovalBtnsEnabled = computed(() => {
            const roles: string[] = [
                UserRole.VALIDATOR,
                UserRole.PERIUM_ADMIN,
                UserRole.DEVELOPER_ADMIN,
            ]
            return (
                ((enableApproval.value && !editable.value) ||
                    (riskAssessmentDetails.value &&
                        riskAssessmentDetails.value.state === Status.submitted) ||
                    enableApproval.value) &&
                roles.includes(role.value) &&
                !approvalContent.value
            )
        })

        // display approval content based on following conditions
        const displayApprovalContent = computed(() => {
            const roles: string[] = [
                UserRole.VALIDATOR,
                UserRole.PERIUM_ADMIN,
                UserRole.DEVELOPER_ADMIN,
            ]
            return (
                approvalContent.value ||
                (((enableApproval.value && !editable.value) ||
                    (riskAssessmentDetails.value &&
                        riskAssessmentDetails.value.state === Status.submitted)) &&
                    roles.includes(role.value))
            )
        })

        // display edit button based on following conditions
        const isEditEnabled = computed(() => {
            const roles: string[] = [
                UserRole.EMPLOYEE,
                UserRole.PERIUM_ADMIN,
                UserRole.DEVELOPER_ADMIN,
            ]
            return (
                !editable.value &&
                riskAssessmentDetails.value &&
                (riskAssessmentDetails.value.state === Status.inprogress ||
                    riskAssessmentDetails.value.state === Status.declined) &&
                roles.includes(role.value)
            )
        })

        //  watch for riskAssessmentId ID changes
        watch(
            [() => props.riskAssessmentId, () => props.newAssessmentSourceId],
            ([riskAssessmentId, newAssessmentSourceId]) => {
                if (riskAssessmentId) {
                    // Show an existing assessment
                    showExistingAssessment()
                } else if (newAssessmentSourceId) {
                    // Create a new assessment reusing the data from this assessment ID
                    createAssessmentFromExisting(newAssessmentSourceId)
                } else {
                    // Create an empty new assessment from scratch
                    createEmptyAssessment()
                }
            },
            { immediate: true }
        )

        const routedFrom = ref('')

        //  watch route path
        watch(
            () => router.app.$route,
            () => {
                routedFrom.value = router.app.$route.name
                getRiskAssessmentInfoBoxText(router.app.$route)
            },
            {
                immediate: true,
            }
        )

        return {
            t,
            language,
            RiskAssessmentSection,
            toast,
            risk,
            riskList,
            riskDetails,
            riskAssessmentDetails,
            editable,
            cancel,
            saveAssessment,
            enableApproval,
            approveAssessment,
            RiskAssessmentApproval,
            approvalContent,
            cancelApproval,
            updateState,
            infomationOp,
            toggleInformation,
            declineAssessment,
            Status,
            convertIdToNames,
            utils,
            role,
            variables,
            isOwnAssessment,
            displayApproveConfirmation,
            displaydeclinedConfirmation,
            successApproveConfirmation,
            successDeclinedConfirmation,
            displaySubmitConfirmation,
            successSubmitConfirmation,
            successSaveConfirmation,
            successCancelConfirmation,
            openCancelConfirmation,
            openSaveConfirmation,
            displaySaveConfirmation,
            displayCancelConfirmation,
            nameInitals,
            goToRecord,
            routedFrom,
            originComponents,
            VIEW_VALIDATION_MODE,
            loading,
            UserRole,
            riskAssessmentInfobox,
            removeMd,
            isSubmitEnabled,
            isApprovalBtnsEnabled,
            isEditEnabled,
            displayApprovalContent,
            fullDescriptionWidth,
            newRiskAssessmentSource,
            isUserValidator
        }
    },
})
